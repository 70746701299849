export const USERS_REQUEST = 'USERS_REQUEST'
export const PROFILES_REQUEST = 'PROFILES_REQUEST'
export const SET_USER_REQUEST = 'SET_USER_REQUEST'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_STUDENTS_REQUEST = 'DELETE_STUDENTS_REQUEST'
export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
