import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from '@/store/modules/user'
import auth from '@/store/modules/auth'
import excursion from '@/store/modules/excursion'
import subscription from '@/store/modules/subscription'
import website_content from '@/store/modules/website_content'
import setting from '@/store/modules/setting'

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        auth,
        excursion,
        subscription,
        website_content,
        setting
    }
})
