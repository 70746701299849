
import { mapState, mapGetters } from 'vuex'
import { UPDATE_USER_REQUEST } from '@/store/actions/user.ts'

import Datepicker from 'vuejs-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'

import AlertModal from '@/components/AlertModal.vue'
import country_list from '@/utils/countries.ts'
const now = new Date()

export default {
    name: 'IDComponent',
    components: {
        AlertModal,
        Datepicker,
    },
    data() {
        return {
            errors: [],
            success: null,

            valid: null,

            nl: nl,

            countries: country_list,

            disabled_issue_dates: {
                to: new Date(now.getFullYear() - 10, now.getMonth() + 3, now.getDate()),
                from: now
            },
            disabled_validity_dates: {
                to: new Date(now.getFullYear(), now.getMonth() + 3, now.getDate()),
                from: new Date(now.getFullYear() + 10, now.getMonth(), now.getDate())
            },
            disabled_bdays: {
                to: new Date(now.getFullYear() - 100, now.getMonth(), now.getDate()),
                from: new Date(now.getFullYear() - 5, now.getMonth(), now.getDate())
            },
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch(UPDATE_USER_REQUEST, this.profile)
                .then(() => {
                    this.success = 'Gegevens opgeslagen'
                })
                .catch((err) => {
                    this.errors.push(err)
                })
            this.$emit('submit')
        },
        setDefaults() {
        },
        validate(vm) {
            const validation_fields = ['document_type', 'document_number', 'document_issue_date',
                'document_validity_date', 'document_country', 'gender', 'document_first_name', 'document_surname',
                'bday', 'nationality']
            for (let i=0; i<validation_fields.length; i++) {
                if (!(vm.$store.state.user.profile[validation_fields[i]])) return false
            }
            return true
        },
    },
    computed: {
        ...mapState({
            profile: (state: any) => state.user.profile
        }),
        ...mapGetters(['isStudent']),
        getSurename() {
            if (this.profile.last_name_prefix) return this.profile.last_name_prefix + ' ' + this.profile.last_name
            return this.profile.last_name
        },
    },
    watch: {
        profile: {
            deep: true,
            handler() {
                this.valid = this.validate(this)
            }
        },
        'profile.document_country': function(newValue) {
            this.profile.nationality = newValue
        },
        'profile.document_issue_date' : function(newValue) {
            if (typeof(newValue) === 'object') {
                // When changed in widget (date object)
                this.profile.document_validity_date = new Date(
                    newValue.getFullYear() + 10, newValue.getMonth(), newValue.getDate()
                )
            }
        }
    }
}
