
import Profile from '@/components/account/Profile.vue'

export default {
    name: 'ProfilePopUp',
    components: {
        Profile,
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('ProfilePopUp-modal')
        },
    },
    watch: {
        '$store.getters.isProfileLoaded': function(newValue) {
            if (newValue) {
                if (Profile.methods.validate(this) === false) this.$bvModal.show('ProfilePopUp-modal')
            }
        }
    }
}
