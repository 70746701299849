
export default {
    name: 'keapal-footer',
    data: function() {
        return {
            year: new Date().getFullYear()
        }
    },
    props: {
        version: {type: String, default: null}
    }
}
