<template>
    <div class="alert alert-dismissible fade show" role="alert" v-if="messages.length > 0 || message">
        <span v-if="message">{{ message }}</span>
        <span v-if="messages.length === 1">{{ messages[0] }}</span>
        <ul v-if="messages.length > 1" class="mb-0">
            <li :key="idx" v-for="(message, idx) in messages">{{ message }}</li>
        </ul>
        <button type="button" class="close" @click="$emit('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

</template>

<script>

export default {
    name: 'Alert',
    props: {
        message: {
            default: null,
            type: String
        },
        messages: {
            default: () => [],
            type: Array
        },
        type: {
            type: String
        }
    }
}

</script>
