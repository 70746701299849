import {
    VERSION_REQUEST,
    SETTINGS_REQUEST,
    SET_SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_ERROR,
    SET_POLICY
} from '@/store/actions/setting.ts'


import apiCall from '@/utils/api'
import Vue from 'vue'


const state = {
    status: '',

    policy: 'privacy_policy',
    version: '',
    settings: {}
}

const getters = {
    getVersion: (state: any) => state.version,
    getSetting: (state: any) => (name: string) => state.settings[name],
}

const actions = {
    [VERSION_REQUEST]: ({ commit }: any) => {
        commit('LOADING')
        return new Promise((resolve, reject) => {
            apiCall({ url: '/settings/version', method: 'GET'})
                .then(resp => {
                    commit(VERSION_REQUEST, resp)
                    commit(SETTINGS_SUCCESS)
                    resolve(resp)
                })
                .catch(() => {
                    commit(SETTINGS_ERROR)
                    reject()
                })
        })
    },
    [SETTINGS_REQUEST]: ({ commit }: any, name: string) => {
        commit('LOADING')
        return new Promise((resolve, reject) => {
            apiCall({ url: '/settings', params: {name: name}, method: 'GET'})
                .then(resp => {
                    commit(SETTINGS_REQUEST, resp)
                    commit(SETTINGS_SUCCESS)
                    resolve()
                })
                .catch((err) => {
                    commit(SETTINGS_ERROR)
                    reject(err)
                })
        })
    },
    [SET_SETTINGS_REQUEST]: async ({ commit, dispatch }: any, setting: any) => {
        commit('LOADING')
        await apiCall({ url: '/settings/set', data: setting, method: 'POST'})
            .then(() => {
                commit(SETTINGS_SUCCESS)
            })
            .catch(() => {
                commit(SETTINGS_ERROR)
            })

        dispatch(SETTINGS_REQUEST, setting.name)
    }
}

const mutations = {
    'LOADING': (state: any) => {
        state.status = 'loading'
    },
    [VERSION_REQUEST]: (state: any, resp: string) => {
        Vue.set(state, 'version', resp)
    },
    [SETTINGS_REQUEST]: (state: any, resp: any) => {
        if (Array.isArray(resp)) {
            var resp_object = {}
            resp.map(function(s) {
                resp_object[s.name] = s.value
            })
            Vue.set(state, 'settings', resp_object)
        } else {
            Vue.set(state.settings, resp.name, resp.value)
        }
    },
    [SETTINGS_SUCCESS]: (state: any) => {
        state.status = 'success'
    },
    [SETTINGS_ERROR]: (state: any) => {
        state.status = 'error'
    },
    [SET_POLICY]: (state: any, policy: string) => {
        Vue.set(state, 'policy', policy)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
