
import { mapState, mapGetters } from 'vuex'
import { UPDATE_USER_REQUEST } from '@/store/actions/user.ts'

import AlertModal from '@/components/AlertModal.vue'

export default {
    name: 'ProfileComponent',
    components: {
        AlertModal
    },
    data() {
        return {
            errors: [],
            success: null,
            valid: null,
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch(UPDATE_USER_REQUEST, this.profile)
                .then(() => {
                    this.success = 'Profiel gewijzigd'
                })
                .catch((err) => {
                    this.errors.push(err)
                })
            this.$emit('submit')
        },
        validate(vm) {
            var validation_fields = ['phone']
            if (vm.$store.getters.isStudent) validation_fields.push('grade')

            for (let i=0; i<validation_fields.length; i++) {
                if (!(vm.$store.state.user.profile[validation_fields[i]])) return false
            }
            return true
        },
    },
    computed: {
        ...mapState({
            profile: (state: any) => state.user.profile
        }),
        ...mapGetters(['isStudent']),
    },
    watch: {
        profile: {
            deep: true,
            handler() {
                this.valid = this.validate(this)
            }
        }
    },
}
