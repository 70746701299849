function urlencode(s: string) {
    return encodeURI(s.replace(/ /g, '_'))
}

function urldecode(s: string) {
    return decodeURI(s).replace(/_/g, ' ')
}


function arrayToObject(A: Array<Object>, key: string){
    return A.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        }
    }, {})
}

function twoDigits(digit: Number) {
    return (digit > 9) ? digit : '0' + digit
}
function parseDate(date: string) {
    let _date = new Date(date)
    return twoDigits(_date.getDate()) + '-' + twoDigits(_date.getMonth() + 1) + '-' +
        twoDigits(_date.getFullYear())
}


function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
            comparison = 1
        } else if (varA < varB) {
            comparison = -1
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        )
    }
}

function yearMonthDay(date: string) {
    var parsed_date = date.match(/(\d*).(\d*).(\d{4})/)
    var year_month_day = ''
    for (let i = 3; i >= 1; i--) {
        var number = Number.parseInt(parsed_date[i])
        year_month_day += (number > 9) ? number : '0' + number
    }
    return Number.parseInt(year_month_day)
}
function compareDates(order = 'asc') {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty('datetime') || !b.hasOwnProperty('datetime')) {
            // property doesn't exist on either object
            return 0
        }

        const varA = yearMonthDay(a['datetime'])
        const varB = yearMonthDay(b['datetime'])

        let comparison = 0
        if (varA > varB) {
            comparison = 1
        } else if (varA < varB) {
            comparison = -1
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        )
    }
}

const policyTranslationSchema = {
    'privacy_policy': 'Privacy policy - scalda-xweek.nl',
    'reis_terms': 'Gedragsvoorwaarden - Reizen',
    'activity_terms': 'Gedragsvoorwaarden - Activiteiten',
    'keuzedelen_terms': 'Gedragsvoorwaarden - Keuzedelen',
}


export { urlencode, urldecode, compareValues, compareDates, arrayToObject, policyTranslationSchema, parseDate}
