
import Navigation from '@/components/Navigation.vue'
import { AUTH_REQUEST } from '@/store/actions/auth'
import { USERS_REQUEST } from '@/store/actions/user'
import { PROFILES_REQUEST } from '@/store/actions/user'
import KeaPalFooter from '@/components/KeaPalFooter.vue'
import WebFooter from '@/components/WebFooter.vue'

import PolicyPopUp from '@/components/popups/PolicyPopUp.vue'
import ProfilePopUp from '@/components/popups/ProfilePopUp.vue'
import IDPopUp from '@/components/popups/IDPopUp.vue'

import { VERSION_REQUEST } from '@/store/actions/setting.ts'
import { CONTENT_REQUEST } from '@/store/actions/website_content.ts'
import { EXCURSION_REQUEST } from '@/store/actions/excursion.ts'
import { SETTINGS_REQUEST } from '@/store/actions/setting.ts'
import { SUBSCRIPTIONS_REQUEST } from '@/store/actions/subscription.ts'

export default {
    name: 'app',
    data: function() {
        return {
            version: '',
        }
    },
    components: {
        Navigation,
        KeaPalFooter,
        WebFooter,
        PolicyPopUp,
        ProfilePopUp,
        IDPopUp,
    },
    methods: {
        checkVersion() {
            return new Promise((resolve, reject) => {
                this.version = localStorage.getItem('version')
                this.$store.dispatch(VERSION_REQUEST)
                    .then(async (version) => {
                        if (!this.version) {
                            localStorage.setItem('version', version)
                            this.version = version
                        } else if (this.version !== version) {
                            let cache_list = await window.caches.keys()
                            for (let i=0; i<cache_list.length; i++){
                                await window.caches.delete(cache_list[i])
                            }
                            // your cache is now deleted
                            localStorage.setItem('version', version)
                            location.reload()
                        }
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        loadSubscriptions(update: boolean = false) {
            return new Promise((resolve, reject) => {
                this.$store.dispatch(SUBSCRIPTIONS_REQUEST, update)
                    .then(() => { resolve() })
                    .catch((err) => { reject(err) })
            })
        },
        loadExcursions: function(update: boolean = false) {
            return new Promise((resolve, reject) => {
                this.$store.dispatch(EXCURSION_REQUEST, { update })
                    .then(() => { resolve() })
                    .catch((err) => { reject(err) })
            })
        }
    },
    created: function() {
        this.checkVersion()

        if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch(AUTH_REQUEST).then(() => {

                if (!this.$store.state.user.profile.accept_privacy_policy) {
                    this.$bvModal.show('PolicyPopup-modal')
                }

                let role = this.$store.state.auth.user.role
                if (role === 'admin' || role === 'teacher') {
                    this.$store.dispatch(USERS_REQUEST)
                    this.$store.dispatch(PROFILES_REQUEST)
                }
            })
        }
    },
    mounted() {
        this.$store.dispatch(CONTENT_REQUEST)
        this.$store.dispatch(EXCURSION_REQUEST)
        this.$store.dispatch(SETTINGS_REQUEST)

        var i = 0
        setInterval(async function () {
            i++
            if (i%30 === 0) {
                // Reload the settings and check for version update every 300 seconds
                await this.checkVersion()
                await this.$store.dispatch(SETTINGS_REQUEST)
            }
            if (i%6 === 0) {
                // Reload the excursions and subscriptions every 60 seconds
                await this.loadExcursions(true)
                await this.loadSubscriptions(true)
            }
        }.bind(this), 10000)
    }
}
