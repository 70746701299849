import {
    USERS_REQUEST, PROFILES_REQUEST,
    SET_USER_REQUEST, UPDATE_USER_REQUEST, DELETE_USER_REQUEST, DELETE_STUDENTS_REQUEST,
    NEW_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST,
    USER_REQUEST, USER_ERROR, USER_SUCCESS
} from '@/store/actions/user'
import apiCall from '@/utils/api'
import { arrayToObject } from '@/utils/index.ts'
import Vue from 'vue'

import { AUTH_LOGOUT } from '@/store/actions/auth'

const state = {
    status: '',

    profile: {},  // Own profile

    users: [],  // All users (id and role)
    profiles: [],  // All profiles (if teacher or admin only)
    profiles_object: {} // same as profiles, but now as object with id as keys
}

const getters = {
    getProfiles: (state: any) => state.profiles,
    getProfile: (state: any) => state.profile,
    isProfileLoaded: (state: any) => !!state.profile.first_name,
}

const actions = {
    [USER_REQUEST]: ({ commit, dispatch }: any) => {
        commit(USER_REQUEST)
        return new Promise((resolve) => {
            apiCall({ url: '/users_info', method: 'GET'})
                .then(resp => {
                    commit('SET_USER', resp)
                    resolve()
                })
                .catch(() => {
                    commit(USER_ERROR)
                    // if resp is unauthorized, logout, to
                    dispatch(AUTH_LOGOUT)
                    resolve()
                })
        })
    },
    [USERS_REQUEST]: ({ commit }: any) => {
        commit(USER_REQUEST)
        apiCall({ url: 'users/all', method: 'GET'})
            .then(resp => {
                commit('USERS_SUCCESS', resp)
            })
            .catch(() => {
                commit(USER_ERROR)
            })
    },
    [PROFILES_REQUEST]: ({ commit }: any) => {
        commit(USER_REQUEST)
        apiCall({ url: 'users_info/all', method: 'GET'})
            .then(resp => {
                commit('PROFILES_SUCCESS', resp)
            })
            .catch(() => {
                commit(USER_ERROR)
            })
    },
    [SET_USER_REQUEST]: ({ commit, dispatch }: any, user: any) => {
        commit(USER_REQUEST)
        return new Promise((resolve, reject) => {
            const endpoint = (user.id) ? '/users_info/update': '/users/add'
            if (!user.id) user.login_id = user.email

            apiCall({ url: endpoint, data: user, method: 'POST'})
                .then(() => {
                    commit(USER_SUCCESS)
                    dispatch(USERS_REQUEST)
                    dispatch(PROFILES_REQUEST)
                    resolve()
                })
                .catch(err => {
                    commit(USER_ERROR)
                    reject(err)
                })
        })
    },
    [UPDATE_USER_REQUEST]: ({ commit, dispatch }: any, user: any) => {
        commit(USER_REQUEST)
        return new Promise((resolve, reject) => {
            apiCall({ url: '/users_info/update/self', data: user, method: 'POST'})
                .then(() => {
                    commit(USER_SUCCESS)
                    dispatch(USER_REQUEST)
                    resolve()
                })
                .catch((err) => {
                    commit(USER_ERROR)
                    reject(err)
                })
        })
    },
    [NEW_PASSWORD_REQUEST]: ({ commit }: any, password_form: any) => {
        commit(USER_REQUEST)
        return new Promise((resolve, reject) => {
            apiCall({ url: '/users/new_password', data: password_form, method: 'POST'})
                .then(() => {
                    commit(USER_SUCCESS)
                    resolve()
                })
                .catch((err) => {
                    commit(USER_ERROR)
                    reject(err)
                })
        })
    },
    [FORGOT_PASSWORD_REQUEST]: ({ commit }: any, login_id: string) => {
        commit(USER_REQUEST)
        return new Promise((resolve, reject) => {
            apiCall({ url: '/users/forgot_password', data: { email: login_id }, method: 'POST'})
                .then(() => {
                    commit(USER_SUCCESS)
                    resolve()
                })
                .catch((err) => {
                    commit(USER_ERROR)
                    reject(err)
                })
        })
    },
    [DELETE_USER_REQUEST]: ({ commit, dispatch }: any, id: string) => {
        commit(USER_REQUEST)
        apiCall({ url: '/users/delete', data: { id: id }, method: 'POST'})
            .then(() => {
                commit(USER_SUCCESS)
                dispatch(USERS_REQUEST)
                dispatch(PROFILES_REQUEST)
            })
            .catch(() => {
                commit(USER_ERROR)
            })
    },
    [DELETE_STUDENTS_REQUEST]: ({ commit, dispatch }: any) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST)
            apiCall({ url: '/users/delete/students', method: 'POST'})
                .then(() => {
                    commit(USER_SUCCESS)
                    dispatch(USERS_REQUEST)
                    dispatch(PROFILES_REQUEST)
                    resolve()
                })
                .catch((err) => {
                    commit(USER_ERROR)
                    reject(err)
                })
        })
    },
}

const mutations = {
    [USER_REQUEST]: (state: any) => {
        state.status = 'loading'
    },
    'USERS_SUCCESS': (state: any, resp: any) => {
        Vue.set(state, 'users', resp)
        state.status = 'success'
    },
    'PROFILES_SUCCESS': (state: any, resp: any) => {
        Vue.set(state, 'profiles', resp)
        Vue.set(state, 'profiles_object', arrayToObject(resp, 'id'))
        state.status = 'success'
    },
    'SET_USER': (state: any, resp: any) => {
        if (typeof(resp.nationality) !== 'string') resp.nationality = 'Netherlands (the)'
        if (typeof(resp.document_country) !== 'string') resp.document_country = 'Netherlands (the)'
        if (typeof(resp.document_first_name) !== 'string') resp.document_first_name = resp.first_name
        if (typeof(resp.document_surname) !== 'string') resp.document_surname = 
                (resp.last_name_prefix) ? resp.last_name_prefix + ' ' + resp.last_name : resp.last_name
        Vue.set(state, 'profile', resp)
        state.status = 'success'
    },
    [USER_SUCCESS]: (state: any) => {
        state.status = 'success'
    },
    [USER_ERROR]: (state: any) => {
        state.status = 'error'
    },
    [AUTH_LOGOUT]: (state: any) => {
        state.profile = {}
        state.profiles = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
