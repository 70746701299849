import {
    SUBSCRIPTIONS_REQUEST,
    SUBSCRIBE_REQUEST,
    PAYMENT_REQUEST,
    UNSUBSCRIBE_REQUEST,
    UNSUBSCRIBE_ALL_REQUEST,
    SUBSCRIPTION_ERROR,
    SUBSCRIPTION_SUCCESS
} from '@/store/actions/subscription'
import { COMMIT_SUBSCRIPTIONS, EXCURSION_REQUEST } from '@/store/actions/excursion.ts'
import apiCall from '@/utils/api'
import Vue from 'vue'
import { compareValues } from '@/utils/index.ts'

const state = {
    status: '',
    subscriptions: []
}

const getters = {
    getSubscriptions: (state: any) => state.subscriptions,
    getMySubscriptions: (state: any, getters: any, rootState: any) => {
        return state.subscriptions.filter(sub => sub.student_id === rootState.user.profile.id)
    },
    // subscriptions for a specific student:
    getSubscriptionsOf: (state: any) => (id) => state.subscriptions.filter(sub => sub.student_id === id),
    // Subscriptions for a specific excursion:
    getSubscriptionsFor: (state: any) => (id) => state.subscriptions.filter(sub => sub.student_id === id),
}

const actions = {
    [SUBSCRIPTIONS_REQUEST]: ({ commit, rootState }: any, update: boolean = false) => {
        if (!update) {
            // if the request is only for updating the current state, do not change the progress state to 'loading'
            commit('REQUEST')
            commit(EXCURSION_REQUEST)
        }

        return new Promise((resolve, reject) => {
            apiCall({ url: 'subscriptions/all', method: 'GET'})
                .then(resp => {
                    commit(SUBSCRIPTION_SUCCESS)
                    commit('SET_SUBSCRIPTIONS', resp)
                    commit(COMMIT_SUBSCRIPTIONS, { rootState, resp })
                    resolve()
                })
                .catch((err) => {
                    commit(SUBSCRIPTION_ERROR, null)
                    reject(err)
                })
        })
    },
    [SUBSCRIBE_REQUEST]: ({ commit, dispatch }: any, subscription: {}) => {
        // subscription (argument) must be an object with at least the project_id and optional the student_id
        commit('REQUEST')
        return new Promise(async (resolve, reject) => {
            await apiCall({ url: 'subscriptions/subscribe', data: subscription, method: 'POST'})
                .then(resp => {
                    commit(SUBSCRIPTION_SUCCESS, resp)
                    resolve()
                })
                .catch(err => {
                    commit(SUBSCRIPTION_ERROR, err)
                    reject(err)
                })

            dispatch(SUBSCRIPTIONS_REQUEST)
        })
    },
    [UNSUBSCRIBE_REQUEST]: ({ commit, dispatch }: any, subscription_id: string) => {
        commit('REQUEST')
        return new Promise(async (resolve, reject) => {
            await apiCall({ url: 'subscriptions/unsubscribe', data: {'id': subscription_id}, method: 'POST'})
                .then(resp => {
                    commit(SUBSCRIPTION_SUCCESS, resp)
                    resolve()
                })
                .catch(err => {
                    commit(SUBSCRIPTION_ERROR, err)
                    reject(err)
                })

            dispatch(SUBSCRIPTIONS_REQUEST)
        })
    },
    [UNSUBSCRIBE_ALL_REQUEST]: ({ commit, dispatch }: any, scope: string) => {
        commit('REQUEST')
        return new Promise(async (resolve, reject) => {
            await apiCall({ url: 'subscriptions/unsubscribe/all', data: {'scope': scope}, method: 'POST'})
                .then(resp => {
                    commit(SUBSCRIPTION_SUCCESS, resp)
                    resolve()
                })
                .catch(err => {
                    commit(SUBSCRIPTION_ERROR, err)
                    reject(err)
                })

            dispatch(SUBSCRIPTIONS_REQUEST)
        })
    },
    [PAYMENT_REQUEST]: async ({ commit, dispatch }: any, payment: {}) => {
        // payment (argument) must be an object with the subscription id and payment info
        // e.g. {id: 123, payment: true}
        commit('REQUEST')
        await apiCall({ url: 'subscriptions/payed', data: payment, method: 'POST'})
            .then(resp => {
                commit(SUBSCRIPTION_SUCCESS, resp)
            })
            .catch((err) => {
                commit(SUBSCRIPTION_ERROR, err)
            })

        dispatch(SUBSCRIPTIONS_REQUEST)
    },
}

const mutations = {
    'REQUEST': (state: any) => {
        state.status = 'loading'
    },
    'SET_SUBSCRIPTIONS': (state: any, resp: any) => {
        try {
            let sorted_subs = resp.sort(compareValues('timestamp', 'asc'))
            Vue.set(state, 'subscriptions', sorted_subs)
        } catch {
            Vue.set(state, 'subscriptions', resp)
        }
    },
    [SUBSCRIPTION_SUCCESS]: (state: any) => {
        state.status = 'success'
    },
    [SUBSCRIPTION_ERROR]: (state: any, err: any) => {
        state.status = 'error'
        if (err.response.status == 406) {
            alert(err.response.data)
        } else {
            alert('Error occurred:\n' + err)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
