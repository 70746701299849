
import ID from '@/components/account/ID.vue'

export default {
    name: 'IDPopUp',
    components: {
        ID,
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('IDPopUp-modal')
        },
    },
}
