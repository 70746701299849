import Vue from 'vue'
import VueRouter, { Route, NavigationGuard } from 'vue-router'
import store from '@/store' // your vuex store

// Import pages that are deffinitely loaded
//import Home from '@/views/Home.vue'

// Import child pages
const FAQ = () => import(
    /* webpackPrefetch: true */
    '@/components/x-week/FAQ.vue'
)



type Next = Parameters<NavigationGuard>[2];

Vue.use(VueRouter)

const ifNotAuthenticated = (to: Route, from: Route, next: Next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/account')
}

const ifAuthenticated = (to: Route, from: Route, next: Next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}

const ifTeacherAuthenticated = (to: Route, from: Route, next: Next) => {
    if (store.getters.isTeacher || store.getters.isAdmin) {
        next()
        return
    }
    next('/')
}

const ifAdminAuthenticated = (to: Route, from: Route, next: Next) => {
    if (store.getters.isAdmin) {
        next()
        return
    }
    next('/')
}


const routes = [
    {
        path: '/',
        name: 'home',
        //component: Home,
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Home.vue'
        ),
        // beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/algemene_informatie',
        name: 'Algemene Informatie',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/XWeek.vue'
        ),
        children: [
            {
                path: 'faq',
                component: FAQ
            }
        ]
    },
    {
        path: '/faq-keuzedelen',
        name: 'FAQ Keuzedelen',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/KeuzedelenInformatie.vue'
        ),
    },
    {
        path: '/faq-xweek',
        name: 'FAQ X-Week',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/FAQ.vue'
        ),
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Account.vue'
        ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/beheer/x-week_inschrijvingen',
        name: 'XWeekSubscriptions',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Subscriptions.vue'
        ),
        //beforeEnter: ifAdminAuthenticated
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            // Replace the ifAdminAuthenticated function here
            if (!store.getters.isAdmin) {
                next('/')
                return
            }

            // Now add the scope
            to.params.scope = 'x-week'
            next()
            return
        }
    },
    {
        path: '/beheer/keuzedelen_inschrijvingen',
        name: 'KeuzedelenSubscriptions',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Subscriptions.vue'
        ),
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            if (!store.getters.isAdmin) {
                next('/')
                return
            }
            to.params.scope = 'keuzedeel'
            next()
            return
        }
    },
    {
        path: '/beheer/accounts',
        name: 'Accounts',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/AccountsAdmin.vue'
        ),
        beforeEnter: ifTeacherAuthenticated
    },

    {
        path: '/keuzedelen',
        name: 'Keuzedelen',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/ExcursionScope.vue'
        ),
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            to.params.scope = 'keuzedeel'
            next()
            return
        }
    },
    {
        path: '/keuzedelen/:excursion',
        //name: this.$route.params.excursion,
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/ExcursionPage.vue'
        ),
        //children: [
        //    {
        //        path: ':tab'
        //    }
        //]
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            to.params.scope = 'keuzedeel'
            next()
            return
        }
    },

    {
        path: '/activiteiten',
        name: 'Excursies',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Excursies.vue'
        ),
    },
    {
        path: '/activiteiten/:scope',
        name: 'ExcursiesDetails',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/ExcursionScope.vue'
        )
    },
    {
        path: '/activiteiten/:scope/:excursion',
        //name: this.$route.params.excursion,
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/ExcursionPage.vue'
        ),
        //children: [
        //    {
        //        path: ':tab'
        //    }
        //]
    },

    {
        path: '/contact',
        name: 'Contact',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Contact.vue'
        ),
    },

    // 404 - handleing
    {
        path: '*',
        name: 'Page not found',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/PageNotFound.vue'
        )
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
