<template>
    <div class="footer-wrap-wrap">
        <hr style="border-color: black; border-style: solid; border-width: 2px;" />
        <div class="footer-wrap">
            <b-modal
                class=""
                :title='policyTitle'
                id="policy-modal"
                size='lg'
                scrollable
                :hide-footer='true'
            >
                <CKEditor
                    :content='getPolicy' @submit='setPolicy(terms, $event)' scope='terms'
                />
            </b-modal>
            <b-link v-b-modal.policy-modal @click='terms = "activity_terms"'>Voorwaarden - Activiteiten</b-link>
            <b-link v-b-modal.policy-modal @click='terms = "reis_terms"'>Voorwaarden - Reizen</b-link>
            <b-link v-b-modal.policy-modal @click='terms = "keuzedelen_terms"'>Voorwaarden - Keuzedelen</b-link>
            <b-link v-b-modal.policy-modal @click='terms = "privacy_policy"'>Privacy voorwaarden</b-link>
            <!--
            <div class="grid-item">
                <CKEditor
                    :content='getContent("-left")' @submit='setContent("left", $event)' scope='Footer Links'
                />
            </div>
            <div class="grid-item">
                <CKEditor
                    :content='getContent("-middle")' @submit='setContent("middle", $event)' scope='Footer Midden'
                />
            </div>
            <div class="grid-item" style="text-align: right">
                <b-link v-b-modal.policy-modal @click='terms = "privacy_policy"'>Privacy voorwaarden</b-link>
                <br />
                <b-link v-b-modal.policy-modal @click='terms = "reis_terms"'>Voorwaarden - Reizen</b-link>
                <br />
                <b-link v-b-modal.policy-modal @click='terms = "activity_terms"'>Voorwaarden - Activiteiten</b-link>
                <CKEditor
                    :content='getContent("-right")' @submit='set_content("right", $event)' scope='Footer Rechts'
                />
            </div>
            -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import CKEditor from '@/components/CKEditor.vue'
import { SET_CONTENT_REQUEST } from '@/store/actions/website_content.ts'

import { policyTranslationSchema } from '@/utils/index.ts'

export default {
    name: 'web_footer',
    data: function() {
        return {
            content_name: 'footer',
            terms: null,
            mounted: false,
        }
    },
    components: {
        CKEditor,
    },
    methods: {
        setContent: function(position, new_content) {
            this.$store.dispatch(
                SET_CONTENT_REQUEST,
                { name: this.content_name + '-' + position, content: new_content }
            ).then()
        },
        getContent: function(position) {
            let store = this.$store.state.website_content.content
            var content_name = this.content_name + position
            if (!(content_name in store)) return ''
            return store[content_name]
        },
        setPolicy: function(policy, new_content) {
            this.$store.dispatch(
                SET_CONTENT_REQUEST,
                { name: policy, content: new_content }
            ).then()
        },
    },
    computed: {
        getPolicy() {
            if (!this.mounted) return
            let store = this.$store.state.website_content.content
            if (!store[this.terms]) return ''
            return store[this.terms]
        },
        policyTitle() {
            if (!this.mounted) return
            if (this.terms === null) return null
            return policyTranslationSchema[this.terms]
        },
    },
    mounted: function() {
        this.mounted = true
    },
}
</script>

<style>
.footer-wrap-wrap {
    margin-top: -15px;
}

.footer-wrap {
    padding: 0px 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}

.grid-item {
    position: relative;
    bottom: 0;
}
</style>

<style scoped>
div {
    border: none;
}

a {
    color: rgba(0, 0, 0, 0.75);
}
a:hover {
    color: black;
}
</style>
