
import { AUTH_LOGIN } from '@/store/actions/auth'
import { USERS_REQUEST } from '@/store/actions/user'
import { PROFILES_REQUEST } from '@/store/actions/user'
import { FORGOT_PASSWORD_REQUEST } from '@/store/actions/user.ts'

export default {
    name: 'login',
    data () {
        return {
            login_id: null,
            password: null,
            show_login_status: false,
        }
    },
    methods: {
        resetModal() {
            this.login_id = null
            this.password = null
        },
        verifyLoginId() {
            return (
                !/[^@]+@(student\.)?scalda\.nl/.test(this.login_id) &&
                !/\d{6}/.test(this.login_id) &&
                !/kenneth(_08@hotmail.com|\.poissonnier@gmail.com)/.test(this.login_id)
            )
        },
        login: function () {
            const { login_id, password } = this

            if (this.verifyLoginId()) {
                this.$refs['login_id-warning'].innerHTML = 'Dit is geen geldig studentnummer of scalda e-mail adres'
                return
            }

            this.show_login_status = true

            this.$store.dispatch(AUTH_LOGIN, { login_id, password })
                .then(() => {
                    this.$bvModal.hide('inlog-modal')
                    this.show_login_status = false

                    if (!this.$store.state.user.profile.accept_privacy_policy) {
                        this.$bvModal.show('PolicyPopup-modal')
                    }

                    let role = this.$store.state.auth.user.role
                    if (role === 'student') this.$router.push('/account')
                    if (role === 'admin' || role === 'teacher') {
                        this.$store.dispatch(USERS_REQUEST)
                        this.$store.dispatch(PROFILES_REQUEST)
                    }
                })
                .catch(() => {
                    this.$refs['warning-header'].innerHTML = 'Login mislukt'
                })
        },
        forgotPassword: function() {
            if (!this.login_id) {
                this.$refs['login_id-warning'].innerHTML = 'Vul je username (studentnummer of e-mail adres) in'
                return
            }
            if (this.verifyLoginId()) {
                this.$refs['login_id-warning'].innerHTML = 'Dit is geen geldig studentnummer of scalda e-mail adres'
                return
            }

            this.$refs['forgot-password'].classList.add('disabled')
            this.$refs['forgot-password-message'].innerHTML = 'Reset password link wordt gegenereerd...'
            this.$store.dispatch(FORGOT_PASSWORD_REQUEST, this.login_id)
                .then(() => {
                    this.$refs['forgot-password'].classList.remove('disabled')
                    this.$refs['forgot-password-message'].innerHTML = null
                    alert('Er is een e-mail naar je verzonden om een nieuw wachtwoord in te stellen.')
                })
                .catch((err) => {
                    this.$refs['forgot-password'].classList.remove('disabled')
                    this.$refs['forgot-password-message'].innerHTML = null
                    alert(err.response.data.message)
                })
        }
    },
    computed: {
        logingStatus() {
            if (!this.show_login_status) return
            return 'Login status: ' + this.$store.state.auth.status +
                '<br />Profiel status: ' + this.$store.state.user.status
        }
    },
    watch: {
        login_id: function() {
            this.$refs['login_id-warning'].innerHTML = null
            this.$refs['warning-header'].innerHTML = null
        },
        password: function() {
            this.$refs['warning-header'].innerHTML = null
        }
    }
}
