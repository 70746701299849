
import { mapGetters } from 'vuex'

// The editor creator to use.
import ClassicEditor from 'custom-ckeditor5-build'

// Import the CKEditor5 css for published content!
import '@/assets/CKEditor5CSS.css'
// Custom CSS for the editor supporting semi-transpirant editor background
import '@/assets/CustomCKEditorCSS.css'

// Api endpoint (for the upload url)
const api_url = window.location.protocol + '//' + window.location.hostname + process.env.VUE_APP_ENV_API_ENDPOINT

export default {
    props: {
        content: {String, default: null},
        scope: {String, default: 'Pagina'},
        height: {String, default: null},
    },
    data() {
        return {
            editor: ClassicEditor,
            edit: false,
            new_content: '',
            published_content_id: 'published_content',
            mounted: false,
            editor_config: {
                simpleUpload: {
                    uploadUrl: api_url + '/uploader',
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.auth.token
                    }
                },
            }
        }
    },
    methods: {
        submit: function() {
            this.$emit('submit', this.new_content)
            this.new_content = ''
            this.toggle_editor()
        },
        Edit: function() {
            this.new_content = this.content
            this.toggle_editor()
        },
        cancel: function() {
            if (this.content == null) {
                this.new_content = ''
            } else {
                this.new_content = this.content
            }
            this.toggle_editor()
        },
        toggle_editor: function() {
            this.edit = !this.edit
            this.$emit('edit', this.edit)
        },
        parseOembed(content) {
            let endpoint = content
            endpoint = endpoint.replace('oembed', 'iframe')
            endpoint = endpoint.replace('url', 'src')
            endpoint = endpoint.replace('watch?v=', 'embed/')
            endpoint = endpoint.replace('oembed', 'iframe')
            return endpoint
        },
    },
    created: function() {
        this.$emit('created', true)
    },
    mounted: function() {
        this.$emit('mounted', true)
    },
    updated: function() {
        this.$emit('updated', true)
    },
    computed: {
        ...mapGetters(['isAdmin']),
        borderStyle: function() {
            if (this.isAdmin && this.scope != 'Pagina') {
                return {
                    '--border-style': 'dotted'
                }
            }
            return {
                '--border-style': 'none'
            }
        },
        publishedContent: function() {
            if (this.content === 'loading' || this.content === null)
                return '<div class="loader"></div>' + '<br />' + '<p style="text-align: center;">Loading ...</p>'
            return this.content
            
        },
        publishedStyle: function() {
            if (this.height) {
                if (this.isAdmin) return 'max-height: calc(' + this.height + ' - 100px);'
                return 'max-height:' + this.height
            }
            return null
        }
    },
}
