
import { mapState } from 'vuex'
import { UPDATE_USER_REQUEST } from '@/store/actions/user.ts'
import { policyTranslationSchema } from '@/utils/index.ts'

export default {
    name: 'PolicyPopup',
    data() {
        return {
            mounted: false
        }
    },
    methods: {
        acceptPolicy() {
            this.profile['accept_' + this.terms] = true
            this.$store.dispatch(UPDATE_USER_REQUEST, this.profile)
            this.$bvModal.hide('PolicyPopup-modal')
        },
    },
    mounted() {
        this.mounted = true
    },
    computed: {
        ...mapState({
            profile: (state: any) => state.user.profile,
            terms: (state: any) => state.setting.policy
        }),
        getPolicy() {
            if (!this.mounted) return
            try {
                return this.$store.state.website_content.content[this.terms]
            } catch {
                return ''
            }
        },
        policyTitle() {
            if (!this.mounted) return
            if (this.terms === null) return null
            return policyTranslationSchema[this.terms]
        },
        getDisclaimer() {
            return 'Door op \'accepteren\' te klikken gaat u akkoord met deze voorwaarden. U kunt deze voorwaarden '+
                'ten alle tijden terug vinden in de footer'
        }
    }
}
