import {
    EXCURSION_REQUEST,
    CREATE_EXCURSION_REQUEST,
    UPDATE_EXCURSION_REQUEST,
    DELETE_EXCURSION_REQUEST,
    DELETE_ALL_EXCURSIONS_REQUEST,
    SET_EXCURSION,
    COMMIT_SUBSCRIPTIONS,
    EXCURSION_ERROR,
    EXCURSION_SUCCESS
} from '@/store/actions/excursion'
import { SUBSCRIPTIONS_REQUEST } from '@/store/actions/subscription.ts'
import apiCall from '@/utils/api'
import Vue from 'vue'

import { compareDates, arrayToObject } from '@/utils/index.ts'

const state = {
    status: '',

    excursion: {},
    excursions: [{}],
    excursions_object: {}
}

const getters = {
    getExcursion: (state: any) => state.excursion,
    getMyExcursions: (state: any) => state.excursions.filter(ex => ex.subscribed !== null),
    getMyHours: (state: any) => {
        try {
            if (!('subscribed' in state.excursions[0])) return
        } catch (err) {
            return
        }

        var hours = 0
        for (var i = 0; i< state.excursions.length; i++) {
            if (state.excursions[i].scope != 'keuzedeel' && typeof(state.excursions[i].subscribed) === 'string') {
                hours += state.excursions[i].hours
            }
        }
        return hours
    }
}

const actions = {
    [EXCURSION_REQUEST]: async ({ commit, dispatch }: any, args: Object = {}) => {
        if (!args['update']) { // if request is to updating the current state, don't change the progress state to 'loading'
            args['update'] = false
            commit(EXCURSION_REQUEST)
        }

        var params = {}
        if (typeof(args) === 'object') {
            if ('id' in args) params['id'] = args['id']
            if ('name' in args) params['name'] = args['name']
        }

        return new Promise((resolve, reject) => {
            apiCall({ url: '/excursions', params: params, method: 'GET'})
                .then(async (resp) => {
                    commit(SET_EXCURSION, resp)
                    await dispatch(SUBSCRIPTIONS_REQUEST, args['update'])
                    resolve()
                })
                .catch(err => {
                    commit(EXCURSION_ERROR)
                    reject(err)
                })
        })
    },
    [CREATE_EXCURSION_REQUEST]: async ({ commit, dispatch }: any, excursion: any) => {
        commit(EXCURSION_REQUEST)
        await apiCall({ url: '/excursions/add', data: excursion, method: 'POST'})
            .then(() => {
                commit(EXCURSION_SUCCESS)
            })
            .catch(err => {
                commit('EXCURSION_SET_ERROR', err)
                commit(EXCURSION_ERROR)
            })

        dispatch(EXCURSION_REQUEST, {'id': excursion.id, 'name': excursion.name})
    },
    [UPDATE_EXCURSION_REQUEST]: async ({ commit, dispatch }: any, excursion: any) => {
        commit(EXCURSION_REQUEST)
        await apiCall({ url: '/excursions/update', data: excursion, method: 'POST'})
            .then(() => {
                commit(EXCURSION_SUCCESS)
            })
            .catch(err => {
                commit('EXCURSION_SET_ERROR', err)
                commit(EXCURSION_ERROR)
            })

        dispatch(EXCURSION_REQUEST, {'id': excursion.id, 'name': excursion.name})
    },
    [DELETE_EXCURSION_REQUEST]: async ({ commit, dispatch }: any, id: string) => {
        commit(EXCURSION_REQUEST)
        await apiCall({ url: '/excursions/delete', data: { id }, method: 'POST'})
            .then(() => {
                commit(EXCURSION_SUCCESS)
            })
            .catch(err => {
                commit('EXCURSION_SET_ERROR', err)
                commit(EXCURSION_ERROR)
            })

        dispatch(EXCURSION_REQUEST)
    },
    [DELETE_ALL_EXCURSIONS_REQUEST]: ({ commit, dispatch }: any, scope: string) => {
        commit(EXCURSION_REQUEST)
        return new Promise(async (resolve, reject) => {
            await apiCall({ url: '/excursions/delete/all', data: {'scope': scope}, method: 'POST'})
                .then(() => {
                    commit(EXCURSION_SUCCESS)
                    resolve()
                })
                .catch(err => {
                    commit('EXCURSION_SET_ERROR', err)
                    commit(EXCURSION_ERROR)
                    reject(err)
                })

            dispatch(EXCURSION_REQUEST)
        })
    }
}

const mutations = {
    [EXCURSION_REQUEST]: (state: any) => {
        state.status = 'loading'
    },
    [EXCURSION_SUCCESS]: (state: any) => {
        state.status = 'success'
    },
    [SET_EXCURSION]: (state: any, resp: any) => {
        if (Array.isArray(resp)) {
            Vue.set(state, 'excursions_object', arrayToObject(resp, '_id'))
            try {
                var sorted_excursions = resp.sort(compareDates('asc'))
                Vue.set(state, 'excursions', sorted_excursions)
            } catch (err) {
                Vue.set(state, 'excursions', resp)
            }
        } else {
            // set the state.excursion
            Vue.set(state, 'excursion', resp)
            Vue.set(state.excursions_object, resp._id, resp)  // Object used in /subscriptions

            // Renew entry of excursion in the state.excursions array
            let excursion_index = state.excursions.findIndex(ex => ex['_id'] === resp['_id'])
            if (excursion_index === -1) {
                state.excursions.unshift(resp)
            } else {
                Vue.set(state.excursions, excursion_index, resp)
            }
        }
        state.status = 'success'
    },
    [COMMIT_SUBSCRIPTIONS]: (state: any, { rootState, resp }) => {
        // Add to each excursion the number of 'subscriptions' and whether the user is 'subscribed'
        function setSubscriptions(excursion, store_state) {
            var subscriptions = resp.filter(sub => sub.excursion_id == excursion._id)
            Vue.set(store_state, 'subscriptions', subscriptions)
            var _subscribed = null
            var _preference = null
            if (rootState.auth.user.role === 'student') {
                var subscribed = (subscriptions.filter(sub => sub.student_id === rootState.auth.user.id))
                if (subscribed.length === 1) {
                    _subscribed = subscribed[0]._id
                    if ('preference' in subscribed[0])
                        _preference = subscribed[0].preference
                    else
                        _preference = 1
                }
            }
            Vue.set(store_state, 'subscribed', _subscribed)
            Vue.set(store_state, 'preference', _preference)
        }

        try {
            state.excursions.map((excursion, index) => {
                setSubscriptions(excursion, state.excursions[index])
            })

            if ('name' in state.excursion) setSubscriptions(state.excursion, state.excursion)
        } catch(err) {
            state.status = 'error'
        }
        state.status = 'success'
    },
    [EXCURSION_ERROR]: (state: any) => {
        // alert('Error occured during the loading of the excursions: ' + err)
        state.status = 'error'
    },
    'EXCURSION_SET_ERROR': (state: any, err: any = null) => {
        alert('Toevoegen/updaten  is mislukt! Error: \n' + err)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
